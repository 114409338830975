/*
  * Component: Footer
  * Author: Kevin F M Santana
  * Date: 28/11/2022 - 14:00
  * Description:
  *
  */

import {FooterStyled} from "./style"
import {Box, Heading, Text} from "grommet";
import {ReactComponent as LogoWhite} from "../../assets/media/logo/RedComet_white__full.svg";
import Button from "../Button";
import {FaWhatsapp} from "react-icons/fa";

interface FooterProps {
}

function Footer(props: FooterProps) {
  return (
    <FooterStyled>
      <Box className={'content-block'}>
        <Box className={"logo"}>
          <LogoWhite/>
        </Box>
        <Box>

        </Box>
      </Box>
      <Box className={'content-block'}>
        {/*<Heading level={4}>Institucional</Heading>*/}
        {/*<ul>*/}
        {/*  <li><Link to={"/code-of-ethics"}>Código de Ética</Link></li>*/}
        {/*  <li><Link to={"/code-of-conduct"}>Código de Conduta</Link></li>*/}
        {/*  <li><Link to={"/anti-corruption-policy"}>Política de anticorrupção</Link></li>*/}
        {/*  <li><Link to={"/personal-data"}>Tratamento de Dados Pessoais</Link></li>*/}
        {/*</ul>*/}
        <Heading level={4}>Ouvidoria</Heading>
        <ul>
          <li><a href={'mailto:ouvidoria@redcomet.com.br'}>ouvidoria@redcomet.com.br</a></li>
        </ul>
      </Box>

      <Box className={'content-block'}>
        <Heading level={4}>Contato</Heading>
        <ul>

          <li className={"space"}>
            <Text as={'p'}><a target={"_blank"} rel={'noreferrer'}
                              href="https://api.whatsapp.com/send?1=pt_BR&phone=5571999543300&text=Olá desejo desejo contratar um de seus serviços."><FaWhatsapp/> BA
              (71) 9 9954-3300</a></Text>
            <Text as={"h6"}>08:00h ás 18:00h</Text>
          </li>
          <li className={"space"}>
            <Text as={'p'}><a target={"_blank"} rel={'noreferrer'}
                              href="https://api.whatsapp.com/send?1=pt_BR&phone=5571999591466&text=Olá desejo desejo contratar um de seus serviços."><FaWhatsapp/> BA
              (71) 9 9959-1466</a></Text>
            <Text as={"h6"}>08:00h ás 18:00h</Text>
          </li>
        </ul>

      </Box>
      <Box className={'content-block'}>
        <Heading level={4}>Plataforma</Heading>
        <Text>
          A plataforma da RedComet fornece o necessários
          para contratação e suporte dos nossos serviços.
        </Text>
        <a href={"https://control.redcomet.com.br"}>
          <Button alternative label={"Painel de Controle"}/>
        </a>
      </Box>
    </FooterStyled>
  )
}

export default Footer
