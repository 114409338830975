/*
  * Component: Router
  * Author: Kevin F M Santana
  * Date: 29/11/2022 - 11:36
  * Description:
  *
  */


import {BrowserRouter, Route, Routes} from "react-router-dom";
import CometDev from "./pages/CometDev";
import Main from "./pages/Main";

interface RouterProps {
}

function Router(props: RouterProps) {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Main/>}/>
        <Route path={"comet-dev"} element={<CometDev/>}/>
        <Route path={"*"} element={<Main/>}/>
      </Routes>
    </BrowserRouter>
  )
}

export default Router
