import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./assets/fonts.css"
import "./assets/global.css"
import "react-toastify/dist/ReactToastify.css"

if(typeof window !== "undefined"){
  if(window.location.protocol === "http:" && window.location.hostname !== "localhost"){
    window.location.href = 'https://'+window.location.host
  }
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <>
    <App/>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
