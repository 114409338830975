import React from 'react';
import {Grommet, ResponsiveContext} from "grommet";
import theme from "./assets/theme";
import Router from "./Router";
import 'animate.css';


function App() {
  return (
    <Grommet full theme={theme}>
      <ResponsiveContext.Consumer>
        {() => (
          <Router/>
        )}
      </ResponsiveContext.Consumer>
    </Grommet>
  );
}

export default App;
