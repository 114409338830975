import styled from "styled-components";
import {Box} from "grommet";


export const WrapperStyled = styled(Box)`
  width: 100%;
  height: 100%;
  display: block;
  overflow-x: hidden;
  scroll-behavior: smooth;
`
