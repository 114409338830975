import {ThemeType} from "grommet";
import {css} from "styled-components";
import {normalizeColor} from "grommet/utils";


const theme = {
  global: {
    font: {
      family: "Bariol-Regular"
    },
    breakpoints: {
      small: {
        value: 428
      },
      medium: {
        value: 769
      }
    },
    colors: {
      focus: {
        light: 'dark-4'
      }
    }
  },
  button: {
    border: {
      radius: "4px",
      color: 'white'
    },
    color: {
      light: "white"
    }
  },
  textInput: {
    container: {
      extend: css`
        border: none;
        border-radius: 6px;
        box-shadow: inset 1px 1px 6px rgba(0, 0, 0, .21);
        margin: 10px 0;
        background: ${props=>normalizeColor("light-1",props.theme)};
        
        input {
          border: none;
          font-weight: normal;
        }
        :focus {
          box-shadow: 0 0 2px black;
        }
      `
    },
  },
  maskedInput: {
    container: {
      extend: css`
        border: none;
        border-radius: 6px;
        box-shadow: inset 1px 1px 6px rgba(0, 0, 0, .21);
        margin: 10px 0;
        background: ${props=>normalizeColor("light-1",props.theme)};
        
        input {
          border: none;
          font-weight: normal;
        }
        :focus {
          box-shadow: 0 0 2px black;
        }
      `
    },
  },
  textArea: {
    extend: css`
      border: none;
      font-weight: normal;
      background: ${props=>normalizeColor("light-1",props.theme)};
      box-shadow: inset 1px 1px 6px rgba(0, 0, 0, .21);
    `
  }

} as ThemeType

export default theme
