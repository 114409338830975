import styled from "styled-components";
import {Box} from "grommet";
import {normalizeColor} from "grommet/utils";

export const MethodologiesStyled = styled(Box)`
  width: 100%;
  height: 100%;
  padding-top: 70px;

  .small &, .medium & {
    padding-top: 5px;
    display: block;
    height: auto;
  }
`
export const MethodologiesHeader = styled(Box)`
  justify-content: center;
  align-items: center;
  text-align: center;
  h2 {
    font-weight: bold;
    text-transform: uppercase;
  }
  
  h3 {
    color: ${props=>normalizeColor("dark-4",props.theme)};
    font-weight: normal;
  }
`

export const MethodologiesInfo = styled(Box)`
  flex-direction: row;
  width: 90%;
  margin: auto;
  z-index: 0;
  padding-bottom: 5%;
  span {
    margin: 10px 0;
  }

  .small &, .medium & {
    text-align: center;
    flex-direction: column-reverse;
  }
  
`

export const MethodologiesImage = styled(Box)`
  flex:1;
  justify-content: center;
  align-items: center;
  svg {
    width: 128px;
    height: 128px;
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
  }
  .small &, .medium & {
    justify-content: center;
    align-items: center;
    svg {
      width: 90px;
    }
    
  }
  
  h3{
    padding-top: 20px;
  }

`
