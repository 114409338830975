import styled from "styled-components";
import {Box} from "grommet";
import {normalizeColor} from "grommet/utils";

export const MenuStyled = styled(Box)`
  flex-direction: row;
  padding: 10px;
  width: 100%;
  justify-content: space-between;
  transition: all 500ms ease;
  align-items: center;
  z-index: 120;
  position: relative;

  .logo {
    height: 30px;

    svg {
      width: 150px;
      height: 30px;
    }
  }


  &.alternative {
    transition: all 500ms ease;
    background: ${props => normalizeColor("dark-1", props.theme)};
    box-shadow: 1px 2px 5px rgba(0, 0, 0, .31);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 140;
  }
`
export const LogoMenu = styled.div`

`

export const LinksMenu = styled.ul`
  display: flex;
  flex-direction: row;
  z-index: 12;

  &.inColumn {
    flex-direction: column !important;

    li {
      padding: 15px 10px;
      border-bottom: 1px solid slategrey;
    }

    li:last-child {
      border-bottom: none;
    }
  }

  .cometDev {
    padding-left: 25px;
    text-shadow: 2px 2px 12px rgba(255, 255, 255, 1);
    font-family: "Bariol-Bold", sans-serif;
    font-size: 15pt;
  }
`
export const LinkOption = styled.li`
  list-style: none;
  color: #fff;
  padding: 0 10px;
  display: flex;
  align-items: center;
  transition: all 400ms ease;
  scroll-behavior: smooth;
  
  &.active {
    transition: all 400ms ease;
    color: #ff4500;
    text-shadow: 2px 2px 12px rgb(255, 69, 0);
    a {
      color: #ff4500;
    }
  }

  a {
    scroll-behavior: smooth;
    color: #fff;
    transition: all 400ms ease;

    &:hover, &.active a {
      transition: all 400ms ease;
      color: #ff4500;
      text-shadow: 2px 2px 12px rgb(255, 69, 0);

    }
  }
`
export const LinksMenuMobile = styled(Box)`
  z-index: 150;
  background: ${props => normalizeColor("dark-1", props.theme)};
  box-shadow: 1px 2px 5px rgba(0, 0, 0, .31);
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  justify-content: space-between;

  .header {
    height: 60px;
    flex-direction: row;
    padding: 0 20px;
    justify-content: space-between;
    align-items: center;

    svg {
      width: 150px;
    }

    .close {
      transition: all 400ms ease;

      svg {
        transition: all 400ms ease;
        width: 30px;
        fill: #fff;
      }

      &:hover {
        svg {
          transition: all 400ms ease;
          fill: orangered;
        }
      }
    }
  }
`
export const LinksMenuMobileButton = styled(Box)`
  width: 50px;

  svg {
    transition: all 400ms ease;
    fill: #fff;
    stroke: #fff;
    width: 35px;
    height: 35px;
  }
`
