/*
  * Component: Hero
  * Author: Kevin F M Santana
  * Date: 18/11/2022 - 11:17
  * Description:
  * Secção da página principal do landing page.
  */

import {HeroBanner, HeroBannerLogo, HeroBannerText, HeroBannerTextWrapper, HeroStyled} from "./style"
import Menu from "../../Menu";
import {Box, Heading} from "grommet";
import {ReactComponent as LogoFull} from "../../../assets/media/logo/RedComet__extended.svg";


interface HeroProps {
}

function Hero(props: HeroProps) {

  return (
    <HeroStyled id={'home'}>
      <div className={'overlay'}/>
      <Menu/>
      <HeroBanner>
        <HeroBannerText>
          <HeroBannerTextWrapper animation={["fadeIn","slideDown"]}>
            <Heading level={1} weight={"normal"}>
              Nós atravessamos o futuro como um cometa.
            </Heading>

            <Box className={'welcome'}>
              <Heading level={5} weight={"normal"} color={'dark-5'}>
                BEM-VINDO Á
              </Heading>
              <Heading level={1} weight={"normal"}>
                RED COMET.
              </Heading>
            </Box>
          </HeroBannerTextWrapper>


        </HeroBannerText>
        <HeroBannerLogo  animation={["fadeIn","slideUp"]}>
          <LogoFull/>
        </HeroBannerLogo>
      </HeroBanner>
    </HeroStyled>
  )
}

export default Hero
