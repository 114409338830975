/*
  * Component: BuildingDev
  * Author: Kevin F M Santana
  * Date: 21/11/2022 - 11:33
  * Description:
  *
  */


import {Box, Heading, Text} from "grommet";
import {ConsultancyButton, ConsultancyHeader, ConsultancyImage, ConsultancyInfo, ConsultancyStyled, ConsultancyText } from "./style";
import Button from "../../Button";

interface BuildingDevProps {}

  function Consultancy(props: BuildingDevProps) {
      return(
        <ConsultancyStyled id={'consultancy'}>
          <Box>
            <ConsultancyHeader>
              <Heading level={2}>Consultoria</Heading>
              <Heading level={3}>Comece agora a alcançar o futuro.</Heading>
            </ConsultancyHeader>
            <ConsultancyInfo>
              <ConsultancyText>
                <Text>
                  Nós realizamos para você uma consultoria totalmente gratuita, desde a análise
                  de caso ao plano de projeto com custo e prazo para desenvolver o sistema
                  ou plataforma que precisar!
                </Text>
              </ConsultancyText>
              <ConsultancyButton>
                <Button label={"Quero a Consultoria"} onClick={()=>{
                  window.open('https://api.whatsapp.com/send?1=pt_BR&phone=5571999543300&text=Ol%C3%A1%20desejo%20desejo%20contratar%20um%20de%20seus%20servi%C3%A7os.')
                }}/>
              </ConsultancyButton>

            </ConsultancyInfo>
          </Box>
          <ConsultancyImage animation={['fadeIn','slideLeft']}/>
        </ConsultancyStyled>
      )
  }

  export default Consultancy
