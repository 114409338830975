/*
  * Component: CometDev
  * Author: Kevin F M Santana
  * Date: 29/11/2022 - 11:40
  * Description:
  *
  */


import Wrapper from "../../components/Wrapper";
import HeroDev from "../../components/cometDev/HeroDev";
import Methodologies from "../../components/cometDev/Methodologies";
import ServicesDev from "../../components/cometDev/ServicesDev";
import Technologies from "../../components/cometDev/Technologies";
import ContactDev from "../../components/cometDev/ContactDev";

interface CometDevProps {
}

function CometDev(props: CometDevProps) {
  return (
    <Wrapper>
      <HeroDev/>
      <Methodologies/>
      <ServicesDev/>
      <Technologies/>
      <ContactDev/>
    </Wrapper>
  )
}

export default CometDev
