import styled from "styled-components";
import {Box} from "grommet";
import {normalizeColor} from "grommet/utils";


export const ContactStyled = styled(Box)`
  width: 100%;
  height: 100%;
  padding-top: 70px;

  .small &, .medium & {
    padding-top: 5px;
    display: block;
    height: auto;
  }
`
export const ContactHeader = styled(Box)`
  justify-content: center;
  align-items: center;
  text-align: center;

  h2 {
    font-weight: bold;
    text-transform: uppercase;
  }

  h3 {
    color: ${props => normalizeColor("dark-4", props.theme)};
    font-weight: normal;
  }
`

export const ContactInfo = styled(Box)`
  flex-direction: row;
  width: 90%;
  margin: auto;
  justify-content: space-around;

  span {
    margin: 10px 0;
  }

  .small &, .medium & {
    text-align: center;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

`

export const ContactDetails = styled(Box)`
  .callCenter {
    h3{
      padding: 30px ;
    }
  }
`
export const ContactDetailsForm = styled(Box)`
  margin: 0 40px;

  .small &, .medium &{
    margin: 0 20px;
    width: 100%;
  }
`
