import styled from "styled-components";
import {Box} from "grommet";

import MainLogo from '../../../assets/media/main-image.webp'

export const HeroStyled = styled(Box)`
  width: 100%;
  height: 100%;
  background: url('${MainLogo}');
  background-repeat: no-repeat;
  background-position: 0;
  background-size: cover;
  position: relative;
  overflow: hidden;
  .overlay {
    width: 100%;
    position: absolute;
    height: 100%;
    background: radial-gradient(circle, rgba(143, 143, 143, 1) -11%, rgba(0, 0, 0, 1) 37%);
    opacity: 50%;
  }
`
export const HeroBanner = styled(Box)`
  width: 100%;
  color: #fff;
  height: 100%;
  z-index: 1;
  flex-direction: row;
  .small &, .medium & {
    flex-direction: column-reverse;
  }
`

export const HeroBannerText = styled(Box)`
  flex: 1;
  align-items: center;
  justify-content: center;

`

export const HeroBannerTextWrapper = styled(Box)`
  width: 75%;
  min-width: 320px;
  
  h1 {
    line-height: 1;
  }
  
  .welcome {
    padding-top: 5%;
  }

  .small &, .medium & {
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`

export const HeroBannerLogo = styled(Box)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  
  .medium &,.small & {
     svg {
       width: 250px;
     }
  }

  svg{
    width: 380px;
  }
`
