import styled from "styled-components";
import {Box} from "grommet";

export const ServicesDevStyled = styled(Box)`
  width: 100%;
  height: auto;
  display: block;
  padding-top: 70px;
  background: linear-gradient(43deg, #F4752E, #ED3437);
  opacity: .89;

  .small &, .medium & {
    height: auto;
    padding: 10px 0;
  }

`
export const ServicesDevHeader = styled(Box)`
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  padding-bottom: 10px;

  h2 {
    font-weight: bold;
    text-transform: uppercase;
  }

  h3 {
    font-weight: normal;
  }
`
export const ServicesDevInfo = styled(Box)`
  width: 100%;
  padding: 0 10%;
  flex-direction: column;
`
export const ServiceContent = styled(Box)`
  width: 100%;
  flex-direction: row;
  margin: 5% 0;

  .small &, .medium & {
    flex-direction: column-reverse;
  }
`

export const ServicesDevData = styled(Box)`
  color: #fff;
  flex: 1;
  justify-content: space-around;

  .small &, .medium & {
    text-align: center;
    align-items: center;
  }
`


export const ServiceDevImage = styled(Box)`
  justify-content: center;
  align-items: center;
  flex: 1;

  img {
    width: 500px;
    border-radius: 5px;
    height: 300px;
    background-size: cover;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, .44);
  }

  .medium & {
    img {
      width: 450px;
      height: 250px;
      margin-bottom: 30px;
    }
  }

  .small & {
    img {
      width: 280px;
      height: 180px;
      margin-bottom: 20px;
    }
  }
`
