import styled from "styled-components";
import {Box} from "grommet";

export const BuildingDevStyled = styled(Box)`
  width: 100%;
  height: 100%;
  padding-top: 70px;
  background: linear-gradient(43deg, #F4752E, #ED3437);
  opacity: .89;

  .small &, .medium & {
    height: auto;
    padding: 10px 0;
  }

`
export const BuildingDevHeader = styled(Box)`
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  padding-bottom: 10px;

  h2 {
    font-weight: bold;
    text-transform: uppercase;
  }

  h3 {
    font-weight: normal;
  }
`

export const BuildingDevInfo = styled(Box)`
  flex-direction: row;
  width: 90%;
  margin: auto;
  align-items: center;
  justify-content: center;
  color: #fff;

  span {
    margin: 10px 0;
  }

  .small &, .medium & {
    flex-direction: column-reverse;
  }
`

export const BuildingDevText = styled(Box)`
  flex: 1;

  .small &, .medium & {
    text-align: center;
    justify-content: center;
    align-items: center;
  }
`
export const BuildingDevImage = styled(Box)`
  flex: 1;
  justify-content: center;
  align-items: center;

  svg {
    width: 200px;
    filter: drop-shadow(3px 5px 6px rgb(0 0 0/ 0.4));
  }

  .small &, .medium & {
    svg {
      width: 100px;
    }

    padding: 10px 0;
  }

`
export const ImageBoxCircle = styled.div`
  border-radius: 500px;
  padding: 20px;
  width: 350px;
  height: 350px;

  .small &, .medium & {
    width: 100px;
    height: 100px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.3);
`
