/*
  * Component: Contact
  * Author: Kevin F M Santana
  * Date: 28/11/2022 - 12:53
  * Description:
  *
  */

import {Controller, useForm} from "react-hook-form"
import {ContactStyled} from "./style"
import {Box, MaskedInput, TextArea, TextInput} from "grommet";
import Button from "../Button";
import {toast} from "react-toastify";
import emailJs from 'emailjs-com'
import {EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, EMAILJS_USERID, EmailPattern} from "../../settings";

interface ContactFormType {
  personalName: string
  companyName: string,
  email: string
  phone: string
  subject: string
  message?: string
}

interface ContactForm {
  companyName: string,
  email: string,
  message: string,
  personalName: string,
  phone: string,
  subject: string
}


function ContactForm() {

  const {control, handleSubmit, reset} = useForm<ContactForm>({
    defaultValues: {
      companyName: "",
      email: "",
      message: "",
      personalName: "",
      phone: "",
      subject: ""
    } as ContactFormType
  })

  function processForm(data: ContactForm) {
    if (data.companyName === "" || data.email === "" || data.message === "" ||
      data.personalName === "" || data.subject === "" || data.phone === "") {
      toast.error("Todos os campos são obrigatórios")
    } else if (data.email.match(EmailPattern) == null) {
      toast.error("O email inserido nao é válido")
    } else {
      emailJs.send(EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, {
        companyName: data.companyName,
        personalName: data.personalName,
        subject: data.subject,
        email: data.email,
        phone: data.phone,
        message: data.message,
      }, EMAILJS_USERID).then(() => {
        toast.success("Tudo certo. Seu email foi enviado com sucesso.")
        reset({
          companyName: "",
          email: "",
          message: "",
          personalName: "",
          phone: "",
          subject: ""
        })
      }, () => {
        toast.error("Oops! não foi possível enviar o email agora! Lamentamos, utilize os outros diversos meios para entrar em contato.")
      });
    }
  }

  return (
    <ContactStyled>
      <form onSubmit={handleSubmit(processForm)}>
        <Controller
          render={({field}) => <TextInput {...field} placeholder={"Nome Pessoal"}/>}
          name={"personalName"}
          control={control}/>
        <Controller
          render={({field}) => <TextInput {...field} placeholder={"Nome da Empresa"}/>}
          name={"companyName"}
          control={control}/>
        <Controller
          render={({field}) => <TextInput {...field} placeholder={"Email"}/>}
          name={"email"}
          control={control}/>
        <Controller
          render={({field}) => <MaskedInput {...field} placeholder={"Telefone"}
                                            mask={[
                                              {fixed: "("},
                                              {length: 2, placeholder: "00", regexp: /[0-9]*/},
                                              {fixed: ")"},
                                              {fixed: " "},
                                              {length: 1, placeholder: "00", regexp: /[0-9]/},
                                              {fixed: " "},
                                              {length: 4, placeholder: "0000", regexp: /[0-9]*/},
                                              {fixed: "-"},
                                              {length: 4, placeholder: "0000", regexp: /[0-9]*/},
                                            ]}
          />}
          name={"phone"}
          control={control}/>

        <Controller
          render={({field}) => <TextInput {...field} placeholder={"Assunto"}/>}
          name={"subject"}
          control={control}/>
        <Controller
          render={({field}) => <TextArea {...field} placeholder={"Mensagem"}
                                         size={"medium"}
                                         resize={false}/>}
          name={"message"}
          control={control}/>

        <Box className={'actionButton'}>
          <Button label={"Enviar Mensagem"} type={'submit'} alternative/>
        </Box>
      </form>
    </ContactStyled>
  )
}

export default ContactForm
