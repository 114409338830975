/*
  * Component: About
  * Author: Kevin F M Santana
  * Date: 21/11/2022 - 09:07
  * Description:
  *
  */

import {MethodologiesHeader, MethodologiesImage, MethodologiesInfo, MethodologiesStyled} from "./style"
import {Heading} from "grommet";


import {ReactComponent as AgileIcon} from "../../../assets/media/methodologies/agile.svg";
import {ReactComponent as LeanIcon} from "../../../assets/media/methodologies/lean.svg";
import {ReactComponent as KanbanIcon} from "../../../assets/media/methodologies/kanban.svg";
import {ReactComponent as CascadeIcon} from "../../../assets/media/methodologies/cascade.svg";

import {motion} from 'framer-motion'

interface AboutProps {
}

function Methodologies(props: AboutProps) {
  return (
    <MethodologiesStyled>
      <MethodologiesHeader>
        <Heading level={2}>Metodologias</Heading>
        <Heading level={3}>Saiba como nós trabalhamos</Heading>
      </MethodologiesHeader>
      <MethodologiesInfo>
        <MethodologiesImage animation={['fadeIn', 'slideUp']}>
          <motion.div
            transition={{ease: "easeInOut", duration: .5, delay:.3}}
            initial={{opacity: 0, transform: 'translateY(-40%)'}}
            whileInView={{opacity: 1, transform: 'translateY(0%)'}}
            viewport={{margin: "10px", once: true}}
          >
            <AgileIcon/>
          </motion.div>
          <Heading level={3}>Agile</Heading>
        </MethodologiesImage>
        <MethodologiesImage animation={['fadeIn', 'slideDown']}>
          <motion.div
            transition={{ease: "easeInOut", duration: .5, delay:.3}}
            initial={{opacity: 0, transform: 'translateY(-40%)'}}
            whileInView={{opacity: 1, transform: 'translateY(0%)'}}
            viewport={{margin: "10px", once: true}}
          >
            <LeanIcon/>
          </motion.div>
          <Heading level={3}>Lean</Heading>

        </MethodologiesImage>
        <MethodologiesImage animation={['fadeIn', 'slideUp']}>
          <motion.div
            transition={{ease: "easeInOut", duration: .5, delay:.3}}
            initial={{opacity: 0, transform: 'translateY(-40%)'}}
            whileInView={{opacity: 1, transform: 'translateY(0%)'}}
            viewport={{margin: "10px", once: true}}
          >
            <CascadeIcon/>
          </motion.div>
          <Heading level={3}>Cascade</Heading>

        </MethodologiesImage>
        <MethodologiesImage animation={['fadeIn', 'slideDown']}>
          <motion.div
            transition={{ease: "easeInOut", duration: .5, delay:.3}}
            initial={{opacity: 0, transform: 'translateY(-40%)'}}
            whileInView={{opacity: 1, transform: 'translateY(0%)'}}
            viewport={{margin: "10px", once: true}}
          >
            <KanbanIcon/>
          </motion.div>
          <Heading level={3}>Kanban</Heading>

        </MethodologiesImage>
      </MethodologiesInfo>
    </MethodologiesStyled>
  )
}

export default Methodologies
