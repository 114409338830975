import styled from "styled-components";
import {Box} from "grommet";
import {normalizeColor} from "grommet/utils";
import ImageTech from '../../../assets/media/alina-grubnyak-unsplash.webp'

export const ServicesStyled = styled(Box)`
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 70px;

  .small &, .medium & {
    height: auto;
    padding: 10px;
  }
`
export const ServiceBackground = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  background: url("${ImageTech}") no-repeat;
  filter: blur(70);
  opacity: .03;
  background-attachment: fixed;
  background-size: cover;
`

export const ServiceHeader = styled(Box)`
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1;

  h2 {
    font-weight: bold;
    text-transform: uppercase;
  }

  h3 {
    color: ${props => normalizeColor("dark-4", props.theme)};
    font-weight: normal;
  }
`
export const ServiceInfo = styled(Box)`
  z-index: 1;
  width: 100%;
  height: 100%;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  padding: 10px 0;
  .small &, .medium & {
    flex-direction: column;

  }
`

export const ServiceDevelopment = styled(Box)`
  .icon {
    align-items: center;
    svg {
      width: 200px;
      height: 200px;
    }
  }
`

export const ServiceData = styled(Box)`
  flex-direction: row;
  .small &, .medium & {
    flex-direction: column;

  }
`

export const ServiceItem = styled(Box)`
  justify-content: start;
  align-items: center;
  min-width: 200px;
  .icon {
    
    width: 200px;
    padding: 20px;
    svg {
      width: 200px;
      height: 200px;
    }

    .small &, .medium & {
      width: auto;
    }
  }
  
  h5{
    font-weight: normal;
    text-align: center;
  }
  padding: 0 5px;
  .small &, .medium & {
    padding: 0;

  }
  
`
