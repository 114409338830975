/*
  * Component: Wrapper
  * Author: Kevin F M Santana
  * Date: 18/11/2022 - 11:11
  * Description:
  * Encapsula todos os dados da página
  */


import {useContext} from "react";
import {BoxExtendedProps, ResponsiveContext} from "grommet";
import {WrapperStyled} from "./style";
import Footer from "../Footer";
import {ToastContainer} from "react-toastify";

interface WrapperProps extends BoxExtendedProps {
}

function Wrapper(props: WrapperProps) {

  const viewport = useContext(ResponsiveContext)

  return (
    <WrapperStyled className={viewport}>
      <ToastContainer/>
      {props.children}
      <Footer/>
    </WrapperStyled>
  )
}

export default Wrapper
