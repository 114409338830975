import styled from "styled-components";
import {Box} from "grommet";

import MainLogo from '../../../assets/media/image-comet-dev.webp'

export const HeroStyled = styled(Box)`
  width: 100%;
  height: 100%;
  background: url('${MainLogo}');
  background-repeat: no-repeat;
  background-position: 0;
  background-size: cover;
  position: relative;
  overflow: hidden; 
  z-index: 10;

  .overlay {
    width: 100%;
    position: absolute;
    height: 100%;
    background: radial-gradient(circle, rgba(143, 143, 143, 1) -11%, rgba(0, 0, 0, 1) 37%);
    opacity: 50%;
  }
`
export const HeroBanner = styled(Box)`
  width: 100%;
  color: #fff;
  height: 100%;
  z-index: 1;
  flex-direction: column;

  .small &, .medium & {
    flex-direction: column-reverse;
  }
`

export const HeroBannerText = styled(Box)`
  flex: 1;
  align-items: center;
  justify-content: center;

  .subTitle {
    width: 70%;
    justify-content: center;
    align-items: center;

    h3 {
      text-align: center;
      width: 100%;
      text-shadow: 1px 2px 5px rgba(255, 255, 255, .6);
    }
  }

  .small &, .medium & {
    .subTitle {
      width: 100%;
    }
  }

`

export const HeroBannerTextWrapper = styled(Box)`
  width: 75%;
  min-width: 320px;
  text-align: center;
  align-items: center;

  h1 {
    line-height: 1;
  }

  .welcome {
    position: relative;
    width:400px;
    .detail {
      position: absolute;
      top: 31px;
      left: -160px;
    }
  }
  .small & {
    .detail {
      top: 5px;
      left: 33px;
    }
  }
  .medium & {
    .detail {
      left: -140px;
    }
    
  }
  .small &, .medium & {
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    
    .welcome {
      width: 320px;
    }
  }
`

export const HeroBannerLogo = styled(Box)`
  flex: 1;
  display: inline-block;
  align-items: center;
  justify-content: center;

  .medium &, .small & {
    svg {
      width: 250px;
    }
  }

  svg {
    width: 380px;
  }
`
