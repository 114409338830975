/*
  * Component: ContactItem
  * Author: Kevin F M Santana
  * Date: 28/11/2022 - 12:56
  * Description:
  *
  */

import {ContactItemStyled} from "./style"
import {Box, Heading, Text} from "grommet";


interface ContactItemProps {
  label?:string
  text?:string
  icon?:JSX.Element
}

function ContactItem(props: ContactItemProps) {
  return (
    <ContactItemStyled>
      <Box className={'icon'}>
        {props.icon}
      </Box>
      <Box className={'info'}>
        {props.text && <Heading level={4}>{props.text}</Heading>}
        {props.label && <Text>{props.label}</Text>}
      </Box>

    </ContactItemStyled>
  )
}

export default ContactItem
