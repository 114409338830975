import styled from "styled-components";
import {Box} from "grommet";
import {normalizeColor} from "grommet/utils";

import Image from '../../../assets/media/florian-olivo.webp'

export const ContactStyled = styled(Box)`
  width: 100%;
  height: 100%;
  padding-top: 70px;
  background-image: url("${Image}"), linear-gradient(-52deg, #F4752E, #ED3437);
  background-repeat: no-repeat, no-repeat;
  background-position: bottom right, left;
  position: relative;
  
  .overlay {
    background-image:  linear-gradient(-52deg, #F4752E, #ED3437);
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: .8;
    top: 0;
    left: 0;
  }
  
  .small &, .medium & {
    padding-top: 5px;
    display: block;
    height: auto;
  }
`
export const ContactHeader = styled(Box)`
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1;
  
  h2 {
    color: ${props => normalizeColor("light-1", props.theme)};
    font-weight: bold;
    text-transform: uppercase;
  }
  
  h3 {
    color: ${props => normalizeColor("light-1", props.theme)};
    font-weight: normal;
  }
`

export const ContactInfo = styled(Box)`
  flex-direction: row;
  width: 90%;
  z-index: 1;
  margin: auto;
  justify-content: space-around;

  span {
    color: ${props => normalizeColor("light-1", props.theme)};
    margin: 10px 0;
  }

  .small &, .medium & {
    text-align: center;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

`

export const ContactDetails = styled(Box)`
  .callCenter {
    h3 {
      color: ${props => normalizeColor("light-1", props.theme)};
      padding: 30px;
    }
  }
  
   span, h4 {
    color: ${props => normalizeColor("light-1", props.theme)} !important;
  }
`
export const ContactDetailsForm = styled(Box)`
  margin: 0 40px;
  color: ${props => normalizeColor("dark-1", props.theme)};

  .small &, .medium & {
    margin: 0 20px;
    width: 100%;
  }
`
