/*
  * Component: Main
  * Author: Kevin F M Santana
  * Date: 29/11/2022 - 11:35
  * Description:
  *
  */


import Wrapper from "../../components/Wrapper";
import Hero from "../../components/landing/Hero";
import About from "../../components/landing/About";
import BuildingDev from "../../components/landing/BuildingDev";
import Services from "../../components/landing/Services";
import Consultancy from "../../components/landing/Consultancy";
import Contact from "../../components/landing/Contact";

interface MainProps {
}

function Main(props: MainProps) {
  return (
    <Wrapper>
      <Hero/>
      <About/>
      <BuildingDev/>
      <Services/>
      <Consultancy/>
      <Contact/>
    </Wrapper>
  )
}

export default Main
