import styled from "styled-components";
import {Box} from "grommet";


export const ContactStyled = styled(Box)`
  height: 100%;
  
  form {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    width: 450px;
    min-width: 320px;
    height: 100%;

    .small &, .medium &{
      width: 100% !important;
      padding: 15px;
    }
  }
  
  .actionButton{
    margin: 10px 0;
  }
`
