/*
  * Component: BuildingDev
  * Author: Kevin F M Santana
  * Date: 21/11/2022 - 11:33
  * Description:
  *
  */


import {Heading, Text} from "grommet";
import {ReactComponent as BuildingDevImageSvg} from "../../../assets/media/dev-image.svg";
import {
  BuildingDevHeader,
  BuildingDevImage,
  BuildingDevInfo,
  BuildingDevStyled,
  BuildingDevText,
  ImageBoxCircle
} from "./style";
import {motion} from "framer-motion";

function BuildingDev() {
  return (
    <BuildingDevStyled id={'building'}>
      <BuildingDevHeader>
        <Heading level={2}>Nós construímos para você</Heading>
        <Heading level={3}>Veja tudo sair do papel alavancando a sua empresa</Heading>
      </BuildingDevHeader>
      <BuildingDevInfo>
        <BuildingDevText>
          <Heading level={2}>
            <motion.div
              transition={{delay: 0.3, duration: .7, ease: "linear"}}
              initial={{opacity: 0, transform: 'translateX(-100%)'}}
              whileInView={{opacity: 1, transform: 'translateX(0%)'}}
              viewport={{once: true}}
            >
              Deseja um ERP ou CRM personalizado para sua empresa ou domínio de negócio?
            </motion.div>
          </Heading>
          <Text>
            <motion.div
              transition={{delay: 0.3, duration: .7, ease: "linear"}}
              initial={{opacity: 0, transform: 'translateX(-100%)'}}
              whileInView={{opacity: 1, transform: 'translateX(0%)'}}
              viewport={{once: true}}
            >
              Nos construímos para você e sua empresa, tudo de forma transparente, seguro
              e de fácil comunicação totalmente remoto e sem burocracias.
            </motion.div>
          </Text>

        </BuildingDevText>
        <BuildingDevImage animation={['fadeIn', 'slideLeft']}>
          <motion.div
            transition={{delay: 0.3, duration: .7, ease: "linear"}}
            initial={{opacity: 0, transform: 'translateX(100%)'}}
            whileInView={{opacity: 1, transform: 'translateX(0%)'}}
            viewport={{once: true, margin: "-40px", amount: "some"}}
          >
            <ImageBoxCircle>
              <BuildingDevImageSvg/>
            </ImageBoxCircle>
          </motion.div>
        </BuildingDevImage>
      </BuildingDevInfo>
    </BuildingDevStyled>
  )
}

export default BuildingDev
