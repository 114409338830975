import styled from "styled-components";
import {Box} from "grommet";

export const FooterStyled = styled(Box)`
  position: relative;
  bottom: 0;
  background: #2B2525;
  height: 400px;
  width: 100%;
  z-index: 1;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px;

  .small &, .medium & {
    flex-direction: column;
    padding: 0 4px;
    display: block;
    height: auto;
    text-align: center;
  }

  .content-block {
    color: #fff;
    max-width: 310px;

    span {
      padding: 10px 0;
    }

    h4 {
      padding: 40px 0 20px;
      text-transform: uppercase;
    }

    ul {
      li {
        &.space {
          padding: 13px 0;
        }

        color: #fff;

        font-size: 12pt;
        list-style: none;

        a {
          line-height: 2;

          transition: all 300ms ease;
          color: #fff;

          &:hover {
            color: orangered;
            transition: all 300ms ease;
          }
        }
      }
    }

    .logo {
      padding: 20px 0;

      svg {
        width: 130px;
        height: 130px;
      }
    }

    .small &, .medium & {
      max-width: 100%;

      h4 {
        max-width: 100% !important;
        text-align: center;
      }

      .logo {
        align-items: center;
      }
    }
  }
`
