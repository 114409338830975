/*
  * Component: Button
  * Author: Kevin F M Santana
  * Date: 26/11/2022 - 18:36
  * Description:
  *
  */


import {ButtonExtendedProps} from "grommet";
import { ButtonGrommetStyled } from "./style";

interface ButtonProps extends ButtonExtendedProps{
  alternative?:boolean
}

function Button(props: ButtonProps) {
  return (
    <ButtonGrommetStyled {...props} className={props.alternative ? "alternative":""}/>
  )
}

export default Button
