import styled from "styled-components";
import {Button} from "grommet";

export const ButtonGrommetStyled = styled(Button)`
  border-radius: 6px;
  background: linear-gradient(to right, #D7D7D7, #FFF);
  box-shadow: 1px 2px 6px rgba(0,0,0,.21);
  padding: 15px;
  text-align: center;
  font-size: 16px;
  border: none;
  color: #333;
  font-weight: bold;
  
  &.alternative {
    background: linear-gradient(to right, #FF5F58, #FA9917);
    color: #fff;
  }
`
