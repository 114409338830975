/*
  * Component: Menu
  * Author: Kevin F M Santana
  * Date: 18/11/2022 - 11:15
  * Description:
  * Menu principal da página no topo
  */

import {LinkOption, LinksMenu, LinksMenuMobile, LinksMenuMobileButton, MenuStyled} from "./style"
import {ReactComponent as Logo} from "../../assets/media/logo/RedComet_white__name.svg";
import {Box, Button, ResponsiveContext} from "grommet";
import {FaCog, FaTimes} from "react-icons/fa";
import {useInView} from "react-intersection-observer";
import {useContext, useState} from "react";
import {FiMenu} from "react-icons/fi";
import {Link, useLocation} from "react-router-dom";


function MenuDesktop(props: any) {

  const location = useLocation()

  return (
    <>
      <LinksMenu className={props.mobile ? 'inColumn' : ""}>
        <LinkOption
          className={location.hash.includes("home") || (location.hash === "" && !location.pathname.includes("comet-dev")) ? "active" : ""}>{location.pathname.includes('comet-dev') ?
          <Link to={"/#home"}>Home</Link> : <a href={"#home"}>Home</a>}</LinkOption>
        <LinkOption className={location.hash.includes("about") ? "active" : ""}><a
          href={'/#about'}>Sobre</a></LinkOption>
        <LinkOption className={location.hash.includes("services") ? "active" : ""}><a
          href={'/#services'}>Serviços</a></LinkOption>
        <LinkOption className={location.hash.includes("consultancy") ? "active" : ""}><a
          href={'/#consultancy'}>Consultoria</a></LinkOption>
        <LinkOption className={location.hash.includes("contact") ? "active" : ""}><a
          href={'/#contact'}>Contato</a></LinkOption>
        <LinkOption className={location.pathname.includes('comet-dev') ? 'cometDev active' : 'cometDev'}><Link
          to={"/comet-dev"}>COMETDEV</Link></LinkOption>
      </LinksMenu>
      <a href={"https://control.redcomet.com.br"}>
        <Button secondary label={"Painel de Controle"} reverse icon={<FaCog/>}/>
      </a>
    </>

  )
}

function MenuMobile() {

  const [open, setOpen] = useState(false)

  return (
    open ?
      <LinksMenuMobile animation={open ? ['fadeIn', 'slideDown'] : ['fadeOut', 'slideUp']}>
        <Box className={'header'}>
          <Logo/>
          <Box className={'close'} onClick={() => setOpen(val => !val)}>
            <FaTimes/>
          </Box>
        </Box>
        <MenuDesktop mobile/>
      </LinksMenuMobile>

      :
      <LinksMenuMobileButton onClick={() => setOpen(true)} animation={['fadeIn', 'slideDown']}>
        <FiMenu/>
      </LinksMenuMobileButton>
  )
}


interface MenuProps {

}

function Menu(props: MenuProps) {

  const viewport = useContext(ResponsiveContext)
  const {ref, inView} = useInView({
    threshold: 1,
    delay: 4,
    rootMargin: "10px"
  })

  return (
    <>
      <div ref={ref} style={{width: "100%", height: "1px"}}/>
      <MenuStyled className={!inView ? (!viewport.includes('large') ? '' : "alternative") : ""}>
        <div className={'logo'}>
          <Link to={"/"}>
            <Logo/>
          </Link>
        </div>
        {viewport.includes('large') ?
          <MenuDesktop/>
          : <MenuMobile/>
        }

      </MenuStyled>
    </>

  )
}

export default Menu
