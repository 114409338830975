/*
  * Component: Contact
  * Author: Kevin F M Santana
  * Date: 28/11/2022 - 12:53
  * Description:
  *
  */

import {ContactDetails, ContactDetailsForm, ContactHeader, ContactInfo, ContactStyled} from "./style"
import {Box, Heading} from "grommet";
import ContactItem from "../../ContactItem";
import {MdEmail} from "react-icons/md";
import {FaPhone} from "react-icons/fa";
import {HiChatBubbleBottomCenter} from "react-icons/hi2";
import ContactForm from "../../ContactForm";


interface ContactProps {
}

function Contact(props: ContactProps) {
  return (
    <ContactStyled id={'contact'}>
      <ContactHeader>
        <Heading level={2}>Contato</Heading>
        <Heading level={3}>Deseja uma de nossas soluções
          e ainda tem dúvidas?</Heading>
      </ContactHeader>
      <ContactInfo>
        <ContactDetails>
          <Box>
            <ContactItem label={"08:00h as 18:00h"} text={"(71) 9 9954-3300"} icon={<FaPhone/>}/>
            <ContactItem label={"08:00h as 18:00h"} text={"(71) 9 9959-1466"} icon={<FaPhone/>}/>
            <ContactItem label={"Qualquer assunto, exceto financeiro"} text={"contato@redcomet.com.br"}
                         icon={<MdEmail/>}/>
            <ContactItem label={"Somente assuntos relacionado ao financeiro"} text={"financeiro@redcomet.com.br"}
                         icon={<MdEmail/>}/>
          </Box>
          <Box className={'callCenter'}>
            <Heading level={3}>Dúvidas, reclamações, sugestões e denúncias</Heading>
            <Box>
              <ContactItem label={"Ouvidoria para todos os assuntos relacionados a RedComet."}
                           text={"oubidoria@redcomet.com.br"}
                           icon={<HiChatBubbleBottomCenter/>}/>
            </Box>
          </Box>
        </ContactDetails>
        <ContactDetailsForm>
          <ContactForm/>
        </ContactDetailsForm>
      </ContactInfo>
    </ContactStyled>
  )
}

export default Contact
