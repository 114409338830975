import styled from "styled-components";
import {Box} from "grommet";


export const ContactItemStyled = styled(Box)`
  flex-direction: row;
  margin: 10px 0;
  .icon {
    background: orangered;
    border-radius: 12px;
    width: 50px;
    min-width: 50px;
    height: 50px;
    padding: 10px ;
    svg {
      fill: #fff;
      stroke: #fff;
      color: #fff;
      width: 32px;
      height: 32px;
    }
  }
  
  .info {
    padding-left: 10px;
    
    h3, h4{
      line-height: 1;
    }
    
    span{
      line-height: 1;
      font-size: 12pt;
    }
  }
`
