import styled from "styled-components";
import {Box} from "grommet";
import ImageConsultancy from "../../../assets/media/consultancy.webp"

export const ConsultancyStyled = styled(Box)`
  width: 100%;
  height: 100%;
  background: linear-gradient(43deg, #F4752E, #ED3437);
  opacity: .89;

  .small &, .medium & {
    height: auto;
    padding: 10px 0;
  }

  flex-direction: row;

`
export const ConsultancyHeader = styled(Box)`
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  padding-top: 70px;
  padding-bottom: 10px;

  h2 {
    font-weight: bold;
    text-transform: uppercase;
  }

  h3 {
    font-weight: normal;
  }

  .small &, .medium & {
    padding-top: 10px;
  }
`

export const ConsultancyInfo = styled(Box)`
  flex-direction: column;
  width: 70%;
  margin: auto;
  height: 100%;
  padding: 20px;
  justify-content: center;
  color: #fff;

  span {
    margin: 10px 0;
  }

  .small &, .medium & {
    flex-direction: column-reverse;
    width: 98%;
  }
`

export const ConsultancyText = styled(Box)`
  flex: 1;
  text-align: center;
  justify-content: center;

  .small &, .medium & {
    text-align: center;
    justify-content: center;
    align-items: center;
  }
`
export const ConsultancyImage = styled(Box)`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: url('${ImageConsultancy}') no-repeat;
  background-size: 100% 100%;
  background-position: 0 0;

  svg {
    width: 200px;
    filter: drop-shadow(3px 5px 6px rgb(0 0 0/ 0.4));
  }

  .small &, .medium & {
    display: none;
    svg {
      width: 100px;
    }

    padding: 10px 0;
  }

`
export const ConsultancyButton = styled(Box)`
  padding: 5% 0;

  .small &, .medium & {
    width: 100%;
  }
`
