/*
  * Component: BuildingDev
  * Author: Kevin F M Santana
  * Date: 21/11/2022 - 11:33
  * Description:
  *
  */


import {Heading, Image, Text} from "grommet";
import {
  ServiceContent,
  ServiceDevImage,
  ServicesDevData,
  ServicesDevHeader,
  ServicesDevInfo,
  ServicesDevStyled
} from "./style";

import {motion} from 'framer-motion'
import Image1 from '../../../assets/media/nubelson.webp'
import Image2 from '../../../assets/media/altumcode.webp'

function ServicesDev() {
  return (
    <ServicesDevStyled>
      <ServicesDevHeader>
        <Heading level={2}>Serviços</Heading>
        <Heading level={3}>Entenda algumas de nossas atividades</Heading>
      </ServicesDevHeader>
      <ServicesDevInfo>
        <ServiceContent>
          <ServicesDevData>
            <Heading level={2}>
              <motion.div
                className={"animate__animated"}
                initial={{animationName: "", animationDelay: "300ms", opacity: 0}}
                whileInView={{animationName: "slideInLeft", opacity: 1}}
                viewport={{amount: "all", once: true}}
              >
                Desenvolvimento de ERPs e CRMs
              </motion.div>
            </Heading>
            <Text>
              <motion.div
                className={"animate__animated"}
                initial={{animationName: "", animationDelay: "300ms", opacity: 0}}
                whileInView={{animationName: "flipInY", opacity: 1}}
                viewport={{amount: "all", once: true}}
              >
                Tenha total apoio no planejamento, desenvolvimento,
                implantação e suporte de aplicações e ferramentas
                completas para a sua empresa. Tudo isso remoto e sem
                complicações.
              </motion.div>
            </Text>
            <Text>
              <motion.div
                className={"animate__animated"}
                initial={{animationName: "", animationDelay: "300ms", opacity: 0}}
                whileInView={{animationName: "flipInY", opacity: 1}}
                viewport={{amount: "all", once: true}}
              >
                Interaja com a equipe de desenvolvimento a qualquer momento
                e esteja apar de tudo o que acontece diariamente.
              </motion.div>
            </Text>
          </ServicesDevData>
          <ServiceDevImage>
            <motion.div
              className={"animate__animated"}
              initial={{animationName: "", animationDelay: "100ms", opacity: 0}}
              whileInView={{animationName: "flipInX", opacity: 1}}
              viewport={{amount: "all", once: true}}
            >
              <Image src={Image1}/>
            </motion.div>
          </ServiceDevImage>
        </ServiceContent>

        <ServiceContent>
          <ServicesDevData>

            <Heading level={2}>
              <motion.div
                className={"animate__animated"}
                initial={{animationName: "", animationDelay: "300ms", opacity: 0}}
                whileInView={{animationName: "slideInLeft", opacity: 1}}
                viewport={{amount: "all", once: true}}
              >
                Atualizações e refatorações
                robustas e seguras
              </motion.div>
            </Heading>
            <Text>
              <motion.div
                className={"animate__animated"}
                initial={{animationName: "", animationDelay: "300ms", opacity: 0}}
                whileInView={{animationName: "flipInY", opacity: 1}}
                viewport={{amount: "all", once: true}}
              >
                Nós fornecemos consultoria integral na análise, elicitação
                e redesign de seus sistemas legados para as versões
                mais recentes da tecnologia de software, tudo isso de
                maneira prática e sustentável a caber no seu bolso.
              </motion.div>
            </Text>
            <Text>
              <motion.div
                className={"animate__animated"}
                initial={{animationName: "", animationDelay: "300ms", opacity: 0}}
                whileInView={{animationName: "flipInY", opacity: 1}}
                viewport={{amount: "all", once: true}}
              >
                Temos uma política de desenvolvimento modular para
                os nossos clientes em que o sistemas legados podem
                ser recriados e modernizados observando as necessidades críticas de cada aplicação!
              </motion.div>
            </Text>
          </ServicesDevData>
          <ServiceDevImage>
            <motion.div
              className={"animate__animated"}
              initial={{animationName: "", animationDelay: "100ms", opacity: 0}}
              whileInView={{animationName: "flipInX", opacity: 1}}
              viewport={{amount: "all", once: true}}
            >
              <Image src={Image2}/>
            </motion.div>
          </ServiceDevImage>
        </ServiceContent>
      </ServicesDevInfo>
    </ServicesDevStyled>
  )
}

export default ServicesDev
