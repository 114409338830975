/*
  * Component: About
  * Author: Kevin F M Santana
  * Date: 21/11/2022 - 09:07
  * Description:
  *
  */

import {AboutHeader, AboutImage, AboutInfo, AboutStyled, AboutText} from "./style"
import {Heading, Text} from "grommet";
import {ReactComponent as AboutImageSvg} from "../../../assets/media/about_company.svg";
import {motion} from 'framer-motion'

interface AboutProps {
}

function About(props: AboutProps) {
  return (
    <AboutStyled id="about">
      <AboutHeader>
        <Heading level={2}>Sobre Nós</Heading>
        <Heading level={3}>Conheça a nossa tragetória</Heading>
      </AboutHeader>
      <AboutInfo>
        <AboutText animation={['fadeIn', 'slideLeft']}>
          <Text>
            <motion.div
              transition={{delay:0.3}}
              initial={{opacity: 0, transform: 'translateX(-100%)'}}
              whileInView={{opacity: 1, transform: 'translateX(0%)'}}
              viewport={{once:true}}
            >
              Somos uma empresa de tecnologia criada por dois irmãos da área de
              tecnologia, trabalhamos juntos a criar, desenvolver e fornecer soluções
              e serviços nuvem para diversas áreas do mercado, tudo feito com o mais
              alto nível de tecnologia e segurança, observando as últimas tendências
              tecnológicas do mundo.
            </motion.div>
          </Text>
          <Text>
            <motion.div
              transition={{delay:0.3}}
              initial={{opacity: 0, transform: 'translateX(-100%)'}}
              whileInView={{opacity: 1, transform: 'translateX(0%)'}}
              viewport={{once:true}}
            >
              Trabalhamos desde 2017 empenhados em conquistar o mercado e revolucionar
              a indústria com sistemas robustos e altamente estruturados, observando
              tudo o que as empresas precisam em que atualmente não atendidas.
            </motion.div>
          </Text>
          <Text>
            <motion.div
              transition={{delay:0.3}}
              initial={{opacity: 0, transform: 'translateX(-100%)'}}
              whileInView={{opacity: 1, transform: 'translateX(0%)'}}
              viewport={{once:true}}
            >
              Desempenhamos o papel da área de T.I de forma remota a capacitar
              integralmente as empresas em contato conosco que não possuem esse
              recurso, ou trabalhamos conjuntamente com empresas em que já possui
              sua área de informatica para alavancar a implantação e desenvolvimento
              de sistemas empresariais.
            </motion.div>
          </Text>
        </AboutText>
        <AboutImage animation={['fadeIn', 'slideRight']}>
          <motion.div
            transition={{delay:0.3, }}
            initial={{opacity: 0, transform: 'translateX(100%)'}}
            whileInView={{opacity: 1, transform: 'translateX(0%)'}}
            viewport={{once:true}}
          >
            <AboutImageSvg/>
          </motion.div>
        </AboutImage>
      </AboutInfo>

    </AboutStyled>
  )
}

export default About
