/*
  * Component: Hero
  * Author: Kevin F M Santana
  * Date: 18/11/2022 - 11:17
  * Description:
  * Secção da página principal do landing page.
  */

import {HeroBanner, HeroBannerLogo, HeroBannerText, HeroBannerTextWrapper, HeroStyled} from "./style"
import Menu from "../../Menu";
import {Box, Heading} from "grommet";
import {ReactComponent as LogoName} from "../../../assets/media/logo/RedComet__name.svg";

function HeroDev() {
  return (
    <HeroStyled animation={["fadeIn"]}>
      <div className={'overlay'}/>
      <Menu/>
      <HeroBanner>
        <HeroBannerText>
          <HeroBannerTextWrapper animation={["fadeIn", "slideDown"]}>
            <Heading level={1} weight={"normal"}>
              DESENVOLVIMENTO DE SOFTWARE
            </Heading>

            <Box className={'welcome'}>
              <Heading level={1} weight={"normal"} className={"detail"}>
                <Box animation={['fadeIn', 'slideRight']}>
                  é com a
                </Box>
              </Heading>
              <HeroBannerLogo animation={["fadeIn", "slideUp"]}>
                <LogoName/>
              </HeroBannerLogo>
            </Box>
          </HeroBannerTextWrapper>
          <Box className={'subTitle'}>
            <Heading level={3}>
              Lance a sua empresa ao sucesso como um cometa automatizando e informatizando os processos do seu negócio.
            </Heading>
          </Box>
        </HeroBannerText>

      </HeroBanner>
    </HeroStyled>
  )
}

export default HeroDev
