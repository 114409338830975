/*
  * Component: About
  * Author: Kevin F M Santana
  * Date: 21/11/2022 - 09:07
  * Description:
  *
  */

import {TechnologiesHeader, TechnologiesImage, TechnologiesInfo, TechnologiesStyled} from "./style"
import {Heading, Text} from "grommet";


import {ReactComponent as FrontendIcon} from "../../../assets/media/frontend.svg";
import {ReactComponent as BackendIcon} from "../../../assets/media/backend.svg";
import {ReactComponent as NuvemIcon} from "../../../assets/media/nuvem.svg";
import {ReactComponent as DatabaseIcon} from "../../../assets/media/database.svg";
import {motion} from "framer-motion";

interface AboutProps {
}

function Technologies(props: AboutProps) {
  return (
    <TechnologiesStyled>
      <TechnologiesHeader>
        <Heading level={2}>Tecnologias</Heading>
        <Heading level={3}>Saiba com o que trabalhamos</Heading>
      </TechnologiesHeader>
      <TechnologiesInfo>
        <TechnologiesImage animation={['fadeIn', 'slideUp']}>
          <motion.div
            className={"animate__animated"}
            initial={{animationName: "", animationDelay: "100ms", opacity: 0}}
            whileInView={{animationName: "flipInX", opacity: 1}}
            viewport={{amount: "all", once: true}}
          >
            <FrontendIcon/>
          </motion.div>
          <Heading level={3}>
            <motion.div
              className={"animate__animated"}
              initial={{animationName: "", animationDelay: "100ms", opacity: 0}}
              whileInView={{animationName: "flipInX", opacity: 1}}
              viewport={{amount: "all", once: true}}
            >
              Tecnologias Frontend
            </motion.div>
          </Heading>
          <Text>
            <motion.div
              className={"animate__animated"}
              initial={{animationName: "", animationDelay: "100ms", opacity: 0}}
              whileInView={{animationName: "slideInUp", opacity: 1}}
              viewport={{amount: "all", once: true}}
            >
              React, VueJs, jQuery, Bootstrap
              React Native, Cordova, Ionic
            </motion.div>
          </Text>
        </TechnologiesImage>
        <TechnologiesImage animation={['fadeIn', 'slideDown']}>
          <motion.div
            className={"animate__animated"}
            initial={{animationName: "", animationDelay: "100ms", opacity: 0}}
            whileInView={{animationName: "flipInY", opacity: 1}}
            viewport={{amount: "all", once: true}}
          >
            <BackendIcon/>
          </motion.div>
          <Heading level={3}>
            <motion.div
              className={"animate__animated"}
              initial={{animationName: "", animationDelay: "100ms", opacity: 0}}
              whileInView={{animationName: "flipInX", opacity: 1}}
              viewport={{amount: "all", once: true}}
            >
              Tecnologias Backend
            </motion.div>
          </Heading>
          <Text>
            <motion.div
              className={"animate__animated"}
              initial={{animationName: "", animationDelay: "100ms", opacity: 0}}
              whileInView={{animationName: "slideInUp", opacity: 1}}
              viewport={{amount: "all", once: true}}
            >
              Java, Node.JS, PHP
            </motion.div>
          </Text>
        </TechnologiesImage>
        <TechnologiesImage animation={['fadeIn', 'slideUp']}>
          <motion.div
            className={"animate__animated"}
            initial={{animationName: "", animationDelay: "100ms", opacity: 0}}
            whileInView={{animationName: "flipInX", opacity: 1}}
            viewport={{amount: "all", once: true}}
          >
            <DatabaseIcon/>
          </motion.div>
          <Heading level={3}>
            <motion.div
              className={"animate__animated"}
              initial={{animationName: "", animationDelay: "100ms", opacity: 0}}
              whileInView={{animationName: "flipInX", opacity: 1}}
              viewport={{amount: "all", once: true}}
            >
              Bancos de Dados
            </motion.div>
          </Heading>
          <Text>
            <motion.div
              className={"animate__animated"}
              initial={{animationName: "", animationDelay: "100ms", opacity: 0}}
              whileInView={{animationName: "slideInUp", opacity: 1}}
              viewport={{amount: "all", once: true}}
            >
              MSSQL, MySql, MongoDB, SQL Server,
              Postgre SQL, FireBase, CostmoDB
            </motion.div>
          </Text>
        </TechnologiesImage>
        <TechnologiesImage animation={['fadeIn', 'slideDown']}>
          <motion.div
            className={"animate__animated"}
            initial={{animationName: "", animationDelay: "100ms", opacity: 0}}
            whileInView={{animationName: "flipInY", opacity: 1}}
            viewport={{amount: "all", once: true}}
          >
            <NuvemIcon/>
          </motion.div>
          <Heading level={3}>
            <motion.div
              className={"animate__animated"}
              initial={{animationName: "", animationDelay: "100ms", opacity: 0}}
              whileInView={{animationName: "flipInX", opacity: 1}}
              viewport={{amount: "all", once: true}}
            >
              Soluções Nuvem
            </motion.div>
          </Heading>
          <Text>
            <motion.div
              className={"animate__animated"}
              initial={{animationName: "", animationDelay: "100ms", opacity: 0}}
              whileInView={{animationName: "slideInUp", opacity: 1}}
              viewport={{amount: "all", once: true}}
            >
              SaveInCloud, Digital Ocean, AWS
              MS-Azure, GCP
            </motion.div>
          </Text>

        </TechnologiesImage>
      </TechnologiesInfo>
    </TechnologiesStyled>
  )
}

export default Technologies
