/*
  * Component: Services
  * Author: Kevin F M Santana
  * Date: 25/11/2022 - 22:12
  * Description:
  *
  */

import {
  ServiceBackground,
  ServiceData,
  ServiceDevelopment,
  ServiceHeader,
  ServiceInfo,
  ServiceItem,
  ServicesStyled
} from "./style"
import {Box, Heading} from "grommet";
import {ReactComponent as CellaLogo} from "../../../assets/media/logo-cella-name.svg";
import {ReactComponent as RedactLogo} from "../../../assets/media/Redact-Logo_FULL.svg";

import {ReactComponent as DevIconSvg} from "../../../assets/media/DEVelopment_icon.svg";

import {motion} from 'framer-motion'

interface ServicesProps {
}

function Services(props: ServicesProps) {
  return (
    <ServicesStyled id={'services'}>
      <ServiceBackground/>
      <ServiceHeader>
        <Heading level={2}>Serviços & Soluções</Heading>
        <Heading level={3}>Descomplique tudo e deixe fluir</Heading>
      </ServiceHeader>
      <ServiceInfo>
        <ServiceData>
          <ServiceItem>
            <Box className={'icon'}>
              <motion.div
                initial={{
                  opacity: 0,
                  transform: "translateY(-40%)",
                  transitionTimingFunction: "ease", transitionProperty: "all", transitionDuration: "400ms"
                }}
                whileInView={{opacity: 1, transform: "translateY(0)"}}
                viewport={{once: false, margin: "120px"}}
              >
                <CellaLogo/>
              </motion.div>

            </Box>
            <Heading level={3}>
              <motion.div
                initial={{
                  opacity: 0,
                  transform: "translateY(70%)",
                  transitionTimingFunction: "ease", transitionProperty: "all", transitionDuration: "400ms"
                }}
                whileInView={{opacity: 1, transform: "translateY(0)"}}
                viewport={{once: false, margin: "120px"}}

              >
                Cella Distribution
              </motion.div>
            </Heading>
            <Heading level={5}>
              <motion.div
                initial={{
                  opacity: 0,
                  transform: "translateY(70%)",
                  transitionTimingFunction: "ease", transitionProperty: "all", transitionDuration: "400ms"
                }}
                whileInView={{opacity: 1, transform: "translateY(0)"}}
                viewport={{once: false, margin: "120px"}}

              >
                Gestão de vendas, estoque e
                centro de distribuição automatizado e inteligente.
              </motion.div>
            </Heading>
            <Heading level={6} color={'green'}>
              <motion.div
                transition={{delay:.8}}
                initial={{
                  opacity:0,
                }}
                whileInView={{opacity: 1}}
                viewport={{ once:false, margin:"120px"}}
              >
              Disponível
              </motion.div>
            </Heading>
          </ServiceItem>
          <ServiceItem>
            <Box className={'icon'}>
              <motion.div
                initial={{
                  opacity: 0,
                  transform: "translateY(-40%)",
                  transitionTimingFunction: "ease", transitionProperty: "all", transitionDuration: "400ms"
                }}
                whileInView={{opacity: 1, transform: "translateY(0)"}}
                viewport={{once: false, margin: "120px"}}

              >
                <RedactLogo/>
              </motion.div>
            </Box>
            <Heading level={3}>
              <motion.div
                initial={{
                  opacity: 0,
                  transform: "translateY(70%)",
                  transitionTimingFunction: "ease", transitionProperty: "all", transitionDuration: "400ms"
                }}
                whileInView={{opacity: 1, transform: "translateY(0)"}}
                viewport={{once: false, margin: "120px"}}

              >
                Redact
              </motion.div>
            </Heading>
            <Heading level={5}>
              <motion.div
                initial={{
                  opacity: 0,
                  transform: "translateY(70%)",
                  transitionTimingFunction: "ease", transitionProperty: "all", transitionDuration: "400ms"
                }}
                whileInView={{opacity: 1, transform: "translateY(0)"}}
                viewport={{once: false, margin: "120px"}}

              >
                Disponibilize em nuvem ou na sua intranet uma maneira
                fácil e seguro e aplicar provas e exames qualificatórios.
              </motion.div>
            </Heading>
            <Heading level={6} color={'orange'}>
              <motion.div
                transition={{delay:.8}}
                initial={{
                  opacity:0,
                }}
                whileInView={{opacity: 1}}
                viewport={{ once:false, margin:"120px"}}
              >
              Em Breve
              </motion.div>
            </Heading>
          </ServiceItem>
        </ServiceData>
        <ServiceDevelopment>
          <ServiceItem>
            <Box className={'icon'}>
              <motion.div
                initial={{
                  opacity: 0,
                  transform: "translateY(-40%)",
                  transitionTimingFunction: "ease", transitionProperty: "all", transitionDuration: "400ms"
                }}
                whileInView={{opacity: 1, transform: "translateY(0)"}}
                viewport={{once: false, margin: "120px"}}
              >
                <DevIconSvg/>
              </motion.div>
            </Box>

            <Heading level={3}>
              <motion.div
                initial={{
                  opacity: 0,
                  transform: "translateY(70%)",
                  transitionTimingFunction: "ease", transitionProperty: "all", transitionDuration: "400ms"
                }}
                whileInView={{opacity: 1, transform: "translateY(0)"}}
                viewport={{once: false, margin: "120px"}}
              >
                Desenvolvimento
              </motion.div>
            </Heading>
            <Heading level={5}>
              <motion.div
                initial={{
                  opacity: 0,
                  transform: "translateY(70%)",
                  transitionTimingFunction: "ease", transitionProperty: "all", transitionDuration: "400ms"
                }}
                whileInView={{opacity: 1, transform: "translateY(0)"}}
                viewport={{once: false, margin: "120px"}}
              >
                Plataformas e Apps sob medida,
                manutenção e controle do jeito que a sua empresa precisa.
              </motion.div>
            </Heading>
          </ServiceItem>
        </ServiceDevelopment>
      </ServiceInfo>

    </ServicesStyled>
  )
}

export default Services
